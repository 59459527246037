/**
 * Created by tyb on 2017/7/24.
 */

import $ from 'jquery'
import QRCode from "qrcode"
import getHttpParams from '../lib/getHttpParams'
import requestHandler from '../lib/requestHandler'

let countdown = 90
const PHONE_LIMIT = 11
const CAPTCHA_LIMIT = 6
let PHONE_CODES = new Array()
let time_out_flag
let phone_now = ''


$(document).ready(() => {
  addEventListener()

  const redirect = getHttpParams.getHttpParams('redirect') || '/'

  serveQRCodeLogin (redirect) 


  $(document).keyup(function (event) {
    if (event.keyCode == 13) {
      $('.container-login.is-show .button-login').click()
    }
  })

  $('.tab-change').click(function () {
    $(this).parent('.container-login').addClass('is-hidden')
    $(this).parent('.container-login').removeClass('is-show')
    $(this)
      .parent('.container-login')
      .siblings('.container-login')
      .removeClass('is-hidden')
    $(this)
      .parent('.container-login')
      .siblings('.container-login')
      .addClass('is-show')

    $('.container-login .error').hide()
    $('.user-password input,#code').val('')
  })

  //账号登录
  $('.account-login .button-login').click(function () {
    const userName = $('.account-login .user-name input').val()
    const userPassword = $('.account-login .user-password input').val()
    const dealPwd = true
    if (userName && userPassword && dealPwd) {
      requestHandler.postData(
        '/api/userLogin/login',
        {
          userName,
          userPassword,
          redirect,
        },
        function (res) {
          if (res.result) {
            if (res.profile) {
              const { userId } = res.profile
              const trackData = {
                page: '登录界面',
                description: '登录结果',
                result: '成功',
                userId,
                login_type: 'password',
                source: document.referrer,
                build_type: env,
              }
              console.log('trackData', trackData)
              sa.identify('u:' + userId)
              sa.track('login_result', trackData)
            }
            location.href = res.redirectUrl
          } else {
            sa.identify('un:' + userName)
            sa.track('login_result', {
              page: '登录界面',
              description: '登录结果',
              result: '失败',
              login_type: 'password',
              source: document.referrer,
              build_type: env,
            })
            $('.account-login .user-password .error').text(res.reason).show()
          }
        }
      )
    } else if (userName && !userPassword) {
      $('.account-login .user-password .error')
      .text("请输入登录密码。如忘记密码，请使用验证码登录")
      .show()
    } else {
      $('.account-login .error').show()
    }
  })

  // 手机号不能超过11位
  const userPhoneObj = $('.phone-login .user-phone input')
  userPhoneObj.keyup(function () {
    let userPhone = userPhoneObj.val()
    let check = false
    if (userPhone.length > PHONE_LIMIT) {
      userPhone = userPhone.substr(0, PHONE_LIMIT)
      userPhoneObj.val(userPhone)
    }
    if (userPhone.length == PHONE_LIMIT) {
      check = true
    }
    checkSvgCaptcha(check)
    if (phone_now == userPhone) return
    phone_now = userPhone
    if (userPhone.length == PHONE_LIMIT) {
      getSvgCaptcha(userPhone)
    }
    $('#svg,#svg .error--not-passed').hide()
    clearTimeout(time_out_flag)
    delete PHONE_CODES[userPhone]
    // if(PHONE_CODES.hasOwnProperty(userPhone)){
    //   settime($(".captcha-send"),userPhone)
    // }else{
    //   $(".captcha-send").html("发送验证码")
    // }
    $('.captcha-send').html('发送验证码')
  })

  const userCaptchaObj = $('.phone-login .user-captcha input')
  userCaptchaObj.keyup(function () {
    const userCaptcha = userCaptchaObj.val()
    if (userCaptcha.length > CAPTCHA_LIMIT) {
      userCaptchaObj.val(userCaptcha.substr(0, CAPTCHA_LIMIT))
    }
  })

  //验证码登录
  $('.phone-login .button-login').click(function () {
    const userPhone = $('.phone-login .user-phone input').val()
    const userCaptcha = $('.phone-login .user-captcha input').val()
    const deal = true
    if (userPhone && userCaptcha && deal) {
      requestHandler.postData(
        '/api/userLogin/login/captcha',
        {
          userPhone,
          userCaptcha,
          redirect,
        },
        function (res) {
          if (res.result) {
            if (res.profile) {
              const { userId } = res.profile
              const trackData = {
                page: '登录界面',
                description: '登录结果',
                result: '成功',
                userId,
                // distinct_id: userId, 低版本sdk不支持 distinct_id
                login_type: 'password',
                redirect: res.redirectUrl,
                source: document.referrer,
                build_type: env,
              }
              console.log('trackData', trackData)
              sa.identify('u:' + userId)
              sa.track('login_result', trackData)
            }
            location.href = res.redirectUrl
          } else {
            sa.identify('up:' + userPhone)

            sa.track('login_result', {
              page: '登录界面',
              description: '登录结果',
              result: 'false',
              login_type: 'captcha',
              build_type: env,
            })
            alert(res.reason)
          }
        }
      )
    } else if (userPhone && !userCaptcha) {
      $('.phone-login .user-captcha .error').show()
    } else {
      $('.phone-login .error').show()
      $('.phone-login .user-captcha .error').show()
    }
  })

  // 是否初始化 svg 验证码
  let hasInitSvgCaptcha = false
  // 是否通过了 svg 验证码
  let hasPassedSvgCaptcha = false
  // 是否开启验证码
  let hasOpenSvgCaptcha = false
  function getSvgCaptcha(phone) {
    requestHandler.getData(
      '/api/userLogin/getCaptchaImg/' + phone,
      function (res) {
        if (res.result == true) {
          hasOpenSvgCaptcha = true
          $('#user-captcha--svg').attr(
            'src',
            'data:image/png;base64,' + res.imgCaptcha
          )
          $('#code').val('')
        } else {
          alert('获取图片验证码出错 请刷新页面')
        }
      }
    )

    return hasOpenSvgCaptcha
  }

  function checkSvgCaptcha(captcha) {
    if (captcha) {
      hasPassedSvgCaptcha = true
      $('.captcha-send').css({ color: '#fc9153' })
    } else {
      $('.captcha-send').css({ color: '#cccdcf' })
      hasPassedSvgCaptcha = false
    }
  }

  // 点击刷新验证码
  $('#user-captcha--svg').click(function () {
    const userPhone = $('.phone-login .user-phone input').val()
    getSvgCaptcha(userPhone)
  })

  // 失去聚焦，检测验证码
  $('.phone-login .user-captcha--svg input').blur(function () {
    hasPassedSvgCaptcha = false

    const capSid = $('.phone-login .user-captcha--svg input').val() || ''
    $('.phone-login .user-captcha--svg .error')
      .not($('.error--not-passed'))
      .hide()
    checkSvgCaptcha(capSid)
  })

  $('.captcha-send').click(function () {
    const userPhone = $('.phone-login .user-phone input').val()
    if (
      PHONE_CODES.hasOwnProperty(userPhone) &&
      PHONE_CODES[userPhone] > 0 &&
      PHONE_CODES[userPhone] < 90
    ) {
      return
    }
    var code = ''
    if (userPhone) {
      code = $('#code').val()
      requestHandler.getData(
        '/api/userLogin/getCaptcha/' + userPhone + '?verificationCode=' + code,
        function (res) {
          console.log(res)
          switch (res.resultStatus) {
            case 0:
              if (!PHONE_CODES.hasOwnProperty(userPhone)) {
                PHONE_CODES[userPhone] = 90
              }
              if (PHONE_CODES[userPhone] === 90) {
                settime($('.captcha-send'), userPhone)
              }
              break
            case 415:
              alert(res.reason)
              $('.user-captcha--svg').hide()
              break
            case 402: //开启图片验证码
            default:
              getSvgCaptcha(userPhone)
              $('.user-captcha--svg').show()
              $('.error--not-passed').text(res.reason).show()
              if (code == '') {
                $('#svg').show()
              }
              break
          }
        }
      )
    } else {
      $('.phone-login .user-phone .error').show()
    }
  })

  $('.container-login input').keyup(function () {
    $('.container-login .error').hide()
  })
})

function settime(obj, phone) {
  // 发送验证码倒计时
  if (PHONE_CODES[phone] === 0) {
    obj.html('&nbsp;重新获取')
    //PHONE_CODES[phone]  = 90;
    delete PHONE_CODES[phone]
    return
  } else {
    PHONE_CODES[phone] = PHONE_CODES[phone] - 1
    obj.html(`重新获取(${PHONE_CODES[phone]}s)`)
  }
  time_out_flag = setTimeout(function () {
    settime(obj, phone)
  }, 1000)
}

/**
 * 注册点击 tab 切换点击事件
 */
function addEventListener() {
  document.querySelector("#switch-to-msg-code").addEventListener("click", function(event) {
    const parent = event.target.parentElement.parentElement
    parent.classList.remove("is-show")
    parent.classList.add("is-hidden")

    parent.nextElementSibling.classList.add("is-show")
    parent.nextElementSibling.classList.remove("is-hidden")
  });

  document.querySelector("#switch-to-password").addEventListener("click", function(event) {
    const parent = event.target.parentElement.parentElement
    parent.classList.remove("is-show")
    parent.classList.add("is-hidden")
    
    parent.previousElementSibling.classList.add("is-show")
    parent.previousElementSibling.classList.remove("is-hidden")
  });

  document.querySelector(".icon-refresh-wrapper").addEventListener("click", function(event) {
    this.classList.add("is-hidden")
    serveQRCodeLogin()
  })
}

/**
 * 返回登录 id 网络请求
 * @returns {Promise<{id: string, status: string}>}
 */
function getLoginId() {
  return new Promise((resolve, reject) => {
    requestHandler.postData(
      '/api/userLogin/qrcode/login',
      null,
      resolve
    )
  })
}


/**
 * 检查 id 有效性 网络请求
 * @param {string} id 
 * @returns {Promise<{status: string}>}
 */
function checkLoginId(id) {
  return new Promise((resolve, reject) => {
    requestHandler.postData(
      '/api/userLogin/qrcode/login',
      {id},
      resolve,
      reject,
    )
  })
}

/**
 * @param {string} redirect
 * 请求 id 然后绘制到 canvas
 */
function serveQRCodeLogin (redirect) {
  redirect = redirect ? redirect : getHttpParams.getHttpParams('redirect') || '/'
  let id = ''
  Promise.all([getLoginId(), getEndpoint()])
  .then(([rst, rst2]) => (id = rst.id, `${rst2.endpoint}/${rst.id}`))
  .then((payload) =>  drawOnCanvas(payload))
  .then(() => startPolling(id))
  .then(result => {
    if (result.profile) {
      const { userId } = result.profile
      const trackData = {
        page: '登录界面',
        description: '登录结果',
        result: '成功',
        userId,
        login_type: 'password',
        source: document.referrer,
        build_type: env,
      }
      console.log('trackData', trackData)
      sa.identify('u:' + userId)
      sa.track('login_result', trackData)
    }
    location.href = result.redirectUrlPreffix + redirect
  })
  .catch((err) => {
    console.error(err)
    document.querySelector(".icon-refresh-wrapper").classList.remove("is-hidden")
  })
}

let polling = false
function startPolling(id){
  const INTERVAL = 2000
  return new Promise((resolve, reject) => {
    if(polling){
      reject(new Error("functioni startPolling is running."))
    } else {
      polling = true
    }
    const timer = setInterval(() => {
      checkLoginId(id)
        .then((result) => {
          const { status } = result
          switch(status) {
            case "INVALID": {
              clearInterval(timer)
              reject(result)
              break
            }
            case "CONFIRMED": {
              clearInterval(timer)
              resolve(result)
              break
            }
            case "PENDING": {
              break
            }
            default: {
              clearInterval(timer)
              reject(result)
              break
            }
          }
        })
        .catch(err => {
          clearInterval(timer)
          reject(err)
        })
        .finally(() => {
          polling = false
        })
    }, INTERVAL);
  })
}

function getEndpoint () {
  return new Promise((resolve, reject) => {
    requestHandler.getData(
      '/api/userLogin/qrcode/mobile',
      resolve
    )
  })
}
/**
 *  把 id 绘制成二维码
 * @param {string} payload 
 * @returns {Promise<any>}
 */
function drawOnCanvas(payload) {
  return new Promise((resolve, reject) => {
    const canvas = document.querySelector('#canvas-qrcode')
    QRCode.toCanvas(canvas, payload, function(error) {
      if(error) {
        reject(error)
      } else {
        resolve()
      }
    })
  })
}